import React from 'react'
import styled from 'styled-components'

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${props => props.backgroundColor ?? '#3d00ff'};

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  > video {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    > video {
      display: block;
    }
    
    img {
      display: none;
    }
  }
`

const VimeoContainer = styled.div`
  position: ${props => props.fixed ? "fixed" : "absolute"};
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor ?? '#3d00ff'};
  
  &::after {
    display: block;
    content: "";
    padding-bottom: 56.25%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  iframe {
    display: none;
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    iframe {
      display: block;
    }

    img {
      display: none;
    }
  }
`

const VideoBackground = ({ source, vimeo, poster, color, fixed }) => {
  return vimeo ? (
    <VimeoContainer
      fixed={fixed}
      backgroundColor={color}
    >
      <img src={poster} alt=""/>

      <iframe
        src={`https://player.vimeo.com/video/${vimeo}?background=1`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        title="Vimeo hero background"
      />
    </VimeoContainer>
  ) : (
    <BackgroundContainer
      backgroundColor={color}
    >
      <img src={poster} alt=""/>

      <video
        autoPlay
        muted
        loop
        poster={poster}
        playsInline
      >
        <source
          src={source}
          type={`video/${source.split('.').pop()}`}
        />
      </video>
    </BackgroundContainer>
  )
}

export default VideoBackground
