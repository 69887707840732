import React from 'react'
import { myContext } from '../../provider'
import { ThemeProvider } from 'styled-components'
import '../style/reset.css'
import '../style/fonts.css'
import { Box } from 'rebass'
import PageHeader from './page-header'
import PageFooter from './page-footer'
import Cursor from './cursor'
import HeadroomHeader from './headroom'

const theme = {
  breakpoints: ['40em', '52em', '64em', '90em'],
  space: [0, 4, 8, 16, 24, 32, 48, 64, 80, 144, 200, 256],
  widths: [0, 400, 600, 800, 1024, 1200],
  fonts: {
    sans: 'Aeonik Pro',
    variable: 'Aeonik Pro Variable',
  },
  fontSizes: [12, 16, 18, 20, 24, 32, 48, 72, 80, 100],
  colors: {
    white: '#fff',
    offWhite: '#f4f4f4',
    cream: '#f1f3f0',
    lightGrey: '#eee',
    grey: '#848386',
    darkGrey: '#181818',
    black: '#181818',
    blue: '#3d00ff',
    green: '#C5F400',
  },
}

const Layout = ({ children, transitionStatus, headerVariant, backgroundVariant, noFooter }) => {

  return (
    <myContext.Consumer>
      {context => (
        <div style={{ 'background': backgroundVariant === 'cream' ? '#F1F3F0' : '#fff' }}>
          <ThemeProvider theme={theme}>
            <Cursor/>

            <PageHeader
              transitionStatus={transitionStatus}
              variant={headerVariant}
            />

            <HeadroomHeader/>

            <Box as="main">
              {children}
            </Box>

            {!noFooter && <PageFooter/>}
          </ThemeProvider>
        </div>
      )}
    </myContext.Consumer>
  )
}

export default Layout
