import React from 'react'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'
import posed from 'react-pose'
import Logo from './logo'
import TransitionLink from 'gatsby-plugin-transition-link'

const FadingHeader = posed.header({
  exiting: {
    opacity: 0,
    transition: {
      ease: [0.59, 0.01, 0.28, 1],
      duration: 500,
    },
  },
  exited: { opacity: 0 },
  entering: {
    opacity: 1,
    transition: {
      ease: [0.59, 0.01, 0.28, 1],
      duration: 600,
    },
  },
  entered: { opacity: 1 },
})

const HeaderInner = styled(Box)`
  display: none;
  position: absolute;
  width: 100%;
  z-index: 1000;
  
  &.is-difference {
    mix-blend-mode: difference;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) and (min-height: ${props => props.theme.breakpoints[0]}) {
    display: block;
  }
`

const Menu = styled.div`
  position: relative;
  display: none;
  transform-origin: top right;
  transform: rotate(90deg) translateX(100%);
  z-index: 100;
  white-space: nowrap;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) and (min-height: ${props => props.theme.breakpoints[0]}) {
    display: inline-block;
  }
`

const MenuLink = styled(TransitionLink)`
  display: inline-block;
  position: relative;
  text-decoration: none;
  
  span {
    display: block;
    color: ${props => props.theme.colors.black};
    font-size: 2.4rem;
    font-weight: 500;
    padding: 0.8rem;
    letter-spacing: 0.01em;
    transition: transform 0.18s cubic-bezier(0, 0, 0, 2.28) 0s;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 4px);
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    background-color: ${props => props.theme.colors.blue};
  }
  
  &:hover {
    span {
      transform: translateY(-8px);
    }
    
    &::after {
      opacity: 1;
      transition: opacity 0.2s ease-in-out 0.05s;
    }
  }
  
  & + * {
    margin-left: ${props => props.theme.space[4]}px;
  }
  
  .is-light &,
  .is-difference & {
    span {
      color: #fff;
    }
  }
`

const PageHeader = ({ transitionStatus, variant }) => {

  return (
    <FadingHeader pose={transitionStatus}>
      <HeaderInner
        px={[4, 5, 6]}
        py={6}
        className={variant ? `is-${variant}` : ''}
      >
        <Flex flexWrap="wrap" alignItems="center">
          <Box width={1 / 2}>
            <TransitionLink to="/">
              <Logo/>
            </TransitionLink>
          </Box>

          <Flex width={1 / 2} justifyContent="flex-end" alignItems="center">
            <Menu>
              <MenuLink to={'/work'} duration={1}><span>Work</span></MenuLink>
              <MenuLink to={'/about'}><span>About</span></MenuLink>
              {/*<MenuLink to={'/careers'}><span>Careers</span></MenuLink>*/}
              <MenuLink to={'/contact-us'}><span>Contact</span></MenuLink>
            </Menu>
          </Flex>
        </Flex>
      </HeaderInner>
    </FadingHeader>
  )
}

export default PageHeader
