/* eslint-disable no-useless-escape */

import React, { useRef, useEffect } from 'react'
import { window, document } from 'browser-monads'
import styled from 'styled-components'
import { isMobile } from "react-device-detect"

const LogoText = styled.h1`
  display: inline-block;
  color: ${props => props.theme.colors.black};
  font-family: 'Aeonik Pro Variable', sans-serif;
  font-weight: 800;
  font-size: ${props => props.theme.fontSizes[6]}px;
  text-transform: lowercase;

  .is-light &,
  .is-difference & {
    color: #fff;
  }
`

const LogoTextMobile = styled.h1`
  display: inline-block;
  color: ${props => props.theme.colors.black};
  font-family: 'Aeonik Pro Variable', sans-serif;
  font-weight: 900;
  font-size: ${props => props.theme.fontSizes[6]}px;
  text-transform: lowercase;

  .is-light &,
  .is-difference & {
    color: #fff;
  }
`

const Logo = () => {
  const stringRef = useRef()

  let mouse = {
    x: 0,
    y: 0,
  }

  let cursor = {
    x: window.innerWidth,
    y: window.innerHeight,
  }

  let logoBounds = {
    top: 80,
    right: 220,
    bottom: 144,
    left: 0,
  }

  console.log(logoBounds);

  let chars

  useEffect(() => {
    addEventListeners()

    return () => removeEventListeners()
  })

  const addEventListeners = () => {
    import ('splitting').then(module => {
      const Splitting = module.default

      Splitting({
        by: 'chars',
      })

      chars = document.querySelectorAll('[data-wave] .char')

      document.addEventListener('mousemove', onMouseMove)

      animate()
    }).catch(error => {
      console.log('An error occurred importing Splitting.JS')
    })
  }

  const removeEventListeners = () => {
    document.removeEventListener('mousemove', onMouseMove)
  }

  const onMouseMove = (e) => {
    cursor.x = e.clientX
    cursor.y = e.clientY
  }

  const animate = () => {
    mouse.x += (cursor.x - mouse.x) / 5
    mouse.y += (cursor.y - mouse.y) / 5

    requestAnimationFrame(() => {
      animate()
    })

    chars.forEach(char => {
      if (mouse.y < 160) {
        const diff = Math.abs(mouse.x - char.getBoundingClientRect().left)
        const perc = diff / 100
        const weight = 300 + (perc * 700)

        char.style = `font-variation-settings: "wght" ${weight < 900 ? weight : 900}`
      } else {
        char.style = `font-variation-settings: "wght" 800`
      }

    })
  }

  if (isMobile) return <LogoTextMobile>ronik</LogoTextMobile>

  return (
    <LogoText data-splitting data-wave ref={stringRef}>ronik</LogoText>
  )
}

export default Logo
