import Headroom from 'react-headroom'
import React, { useState } from 'react'
import styled from 'styled-components'
import TransitionLink from 'gatsby-plugin-transition-link'

const HeadroomContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10000;
  
  .headroom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.colors.black};
    height: 8.4rem;
    padding: 0 2.6rem;
    z-index: 10000;
  }
  
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  
  .headroom--scrolled {
    transition: transform 300ms cubic-bezier(0.71, -0.01, 0.29, 1);
  }
  
  .headroom--unpinned {
    transform: translateY(-100%);
  }
  
  .headroom--pinned {
    transform: translateY(0%);
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) and (min-height: ${props => props.theme.breakpoints[0]}) {
    .headroom {
      height: 8rem;
      padding: 0 4.8rem;
    } 
    
    .headroom--unfixed {
      transform: translateY(-100%);
      transition: transform 300ms cubic-bezier(0.71, -0.01, 0.29, 1);
    }
  }
`

const Logo = styled.span`
    display: inline-block;
    color: ${props => props.theme.colors.white};
    font-weight: 900;
    font-size: 36px;
    text-transform: lowercase;
    user-select: none;
    
    .is-light &,
    .is-difference & {
        color: #fff;
    }
    
    @media (min-width: ${props => props.theme.breakpoints[1]}) and (min-height: ${props => props.theme.breakpoints[0]}) {
      font-size: ${props => props.theme.fontSizes[5]}px;
    }
`

const Menu = styled.div`
  display: none;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: block
  }
`

const MenuLink = styled(TransitionLink)`
  display: inline-block;
  position: relative;
  text-decoration: none;
  
  span {
    display: block;
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.variable};
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0.8rem;
    letter-spacing: 0.01em;
    transition: transform 0.18s cubic-bezier(0, 0, 0, 2.18);
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 4px);
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    background-color: ${props => props.theme.colors.blue};
  }
  
  &:hover {
    span {
      transform: translateY(-8px);
    }
    
    &::after {
      opacity: 1;
      transition: opacity 0.1s ease-in-out 0.05s;
    }
  }
  
  & + * {
    margin-left: ${props => props.theme.space[4]}px;
  }
`

const Hamburger = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: 0 none !important;
  appearance: none;
  padding: 0;
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  
  > span {
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${props => props.theme.colors.white};
      transition: all 0.2s ease;
      transform-origin: center center;
    }
    
    &::before {
      top: ${props => props.open ? '11px' : '8px'};
      transform: ${props => props.open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
    
    &::after {
      top: ${props => props.open ? '11px' : '14px'};
      transform: ${props => props.open ? 'rotate(45deg)' : 'rotate(0)'};
    }
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: none;
  }
`

const MobileMenu = styled.div`
  opacity: 0;
  visibility: hidden;
  transform: translateY(-8rem);
  background-color: ${props => props.theme.colors.blue};
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: transform 0.16s ease-in-out, opacity 0.16s ease-in-out, visibility 0s 0.16s;
  
  .is-open & {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    transition: transform 0.16s ease-in-out, opacity 0.16s ease-in-out, visibility 0s 0s;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: none;
  }
`;

const MobileMenuLink = styled(TransitionLink)`
  display: block;
  text-decoration: none;
  color: #fff;
  font-family: ${props => props.theme.fonts.variable};
  font-weight: 380;
  font-size: ${props => props.theme.fontSizes[6]}px;
  padding: 0.4rem 0;
  
  & + * {
    margin-top: 4rem;
  }
`

const HeadroomHeader = () => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const toggleMobile = () => {
    setMobileOpen(!mobileOpen)
    document.getElementsByTagName('body')[0].classList.toggle('is-locked');
  }

  const handleClick = () => {
    document.getElementsByTagName('body')[0].classList.remove('is-locked');
  }

  return (
    <HeadroomContainer className={`${mobileOpen ? 'is-open' : ''}`}>
      <Headroom
        className="page-header__headroom"
        disableInlineStyles
        pinStart={200}
      >
        <TransitionLink to="/">
          <Logo>Ronik</Logo>
        </TransitionLink>

        <Menu>
          <MenuLink to={'/work'} duration={1}><span>Work</span></MenuLink>
          <MenuLink to={'/about'}><span>About</span></MenuLink>
          {/*<MenuLink to={'/careers'}><span>Careers</span></MenuLink>*/}
          <MenuLink to={'/contact-us'}><span>Contact</span></MenuLink>
        </Menu>

        <Hamburger open={mobileOpen} onClick={() => {
          toggleMobile()
        }}>
          <span></span>
        </Hamburger>
      </Headroom>

      <MobileMenu>
        <MobileMenuLink to="/work" onClick={() => { handleClick(); }}>Our Work</MobileMenuLink>

        <MobileMenuLink to="/about" onClick={() => { handleClick(); }}>About Us</MobileMenuLink>

        <MobileMenuLink to="/contact-us" onClick={() => { handleClick(); }}>Get in Touch</MobileMenuLink>
      </MobileMenu>
    </HeadroomContainer>
  )
}

export default HeadroomHeader
