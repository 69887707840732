import React, { useState } from 'react';
/*import { document } from 'browser-monads';*/

export const myContext = React.createContext();

const Provider = props => {
  const [cursorStuck, setCursorStuck] = useState(false);

  return (
    <myContext.Provider value={{
      cursorStuck,
      stickCursor: () => setCursorStuck(false),
      unstickCursor: () => setCursorStuck(true)
    }}>
      {props.children}
    </myContext.Provider>
  )
}

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
)
