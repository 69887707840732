/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Text } from 'rebass'
import styled from 'styled-components'
import TransitionLink from 'gatsby-plugin-transition-link'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import VideoBackground from './video-background'
import Arrow from './arrow-sml-white.svg'
import FrostLogo from './ronik-logo-white.svg'

const Footer = styled(Box)`
  position: relative;
  color: #fff;
  font-family: ${props => props.theme.fonts.variable};
  background-color: ${props => props.theme.colors.black};
  padding-top: 4.8rem;
  padding-bottom: 2.4rem;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding-top: 8rem;
    padding-bottom: 4.2rem;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    padding-top: 18.8rem;
  }
`

const FooterLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(100% - 64px);
  opacity: 0.2;
  mix-blend-mode: soft-light;
  
  img {
    width: 100%;
  }
`;

const FooterWrap = styled.div`
  position: relative;
  z-index: 10;
`;

const FooterContent = styled(Box)`
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: flex;
    padding-bottom: 12rem;
    
    > :last-of-type {
      display: flex;
      justify-content: flex-end;
    }
  }
`

const Menu = styled(Box)`
  list-style: none;
  padding-left: 0;
  margin-bottom: 2.4rem;
  
  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    font-family: ${props => props.theme.fonts.variable};
    font-weight: 380;
    font-size: ${props => props.theme.fontSizes[5]}px;
    line-height: 1.03;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    margin-bottom: 4.8rem;
    
    a {
      font-size: ${props => props.theme.fontSizes[6]}px;
    }
  }
  
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    margin-bottom: 8.2rem;
    
    a {
      font-size: 8.4rem;
    }
  }
`

const MenuLink = styled.a`
  display: block;
  text-decoration: none;
  color: #fff;
  font-family: ${props => props.theme.fonts.variable};
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes[2]}px;
  padding: 0.4rem 0;
  
  & + * {
    margin-top: 0.4rem;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: inline-block;
    font-size: 1.5rem;
    padding: 0 1.8rem;
    
    &:first-of-type {
      padding-left: 0;
    }
  }
`

const MailchimpForm = styled.form`
  display: inline-block;
  position: relative;
  margin: 4rem 0 6.4rem;
  width: 100%;
  max-width: 42rem;
  
  button {
    appearance: none;
    background: transparent;
    border: 0 none;
    padding: 5px 0;
    color: #fff;
    font-size: ${props => props.theme.fontSizes[1]}px;
    font-weight: 700;
    position: absolute;
    right: 0;
    top: 0.2rem;
    cursor: pointer;
    
    img {
      width: 6.4rem;
      height: 1.3rem;
    }
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    margin: 0 0 0 auto;
    
    button {
      top: 0;
      
      img {
        width: 8rem;
        height: 1.6rem;
      }
    }
  }
`

const MailchimpElement = styled.div`
  position: relative;
  
  label {
    position: absolute;
    top: 0.3rem;
    left: 0;
    color: #fff;
    font-size: ${props => props.theme.fontSizes[1]}px;
    font-weight: 400;
    transition: transform 0.12s ease-in;
    transform-origin: top left;
    pointer-events: none;
  }

  input {
    display: block;
    background-color: transparent;
    appearance: none;
    border-radius: 0;
    border: 0 none;
    border-bottom: 1px solid #fff;
    padding: 0.8rem 8rem 1rem 0;
    width: calc(100% - 8rem);
    outline: none;       
    color: #fff;
    overflow: hidden;
    font-size: ${props => props.theme.fontSizes[1]}px;
    font-weight: 500;
    
    &:focus + label,
    &:valid + label {
      transform: translateY(-32px) scale(0.8);
    }
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {    
    input,
    label {
      font-size: 1.8rem;    
    }
    
    input {
      padding: 0.8rem 8.8rem 1rem 0;
      width: calc(100% - 8.8rem);
    }
  }
`

const Copyright = styled(Text)`
  color: #fff;
  opacity: 0.5;
  font-family: ${props => props.theme.fonts.variable};
  font-size: 1.4rem;
  font-weight: 600;
  text-align: right;
`

function PageFooter() {
  const result = useStaticQuery(graphql`
  {
    allPrismicFooter {
      edges {
        node {
          data {
            heading {
              text
            }
            background_video {
              url
            }
            background_vimeo_id
            background_image {
              url
            }
            email
            address_1
            address_2
            instagram_url {
              url
            }
            vimeo_url {
              url
            }
            dribbble_url {
              url
            }
            linkedin_url {
              url
            }
            facebook_url {
              url
            }
            twitter_url {
              url
            }
          }
        }
      }
    }
    }
  `)

  const footer = result.allPrismicFooter.edges[0].node.data

  const [email, setEmail] = useState('')
  const [statusMessage, setStatusMessage] = useState()

  const currentYear = new Date().getFullYear()

  const _handleSubmit = async (e) => {
    e.preventDefault()

    const result = await addToMailchimp(email)

    if (result) {
      setStatusMessage(result)
    }
  }

  return (
    <Footer px={[4, 5, 6]}>
      {((footer.background_video && footer.background_video.url) || footer.background_vimeo_id) && (
        <VideoBackground
          source={footer.background_video.url}
          vimeo={footer.background_vimeo_id}
          poster={footer.background_image.url}
        />
      )}

      <FooterLogo>
        <img src={FrostLogo} alt=""/>
      </FooterLogo>

      <FooterWrap>
        <Menu as="ul">
          <li>
            <TransitionLink to={'/work'}>See the work</TransitionLink>
          </li>

          <li>
            <TransitionLink to={'/about'}>About us</TransitionLink>
          </li>

          <li>
            <TransitionLink to={'/contact-us'}>Get in touch</TransitionLink>
          </li>
        </Menu>

        <FooterContent>
          <Box width={[1, 1, 1 / 2]}>
            {(footer.facebook_url && footer.facebook_url.url.length > 0) &&
            <MenuLink href={footer.facebook_url.url} target="_blank" rel="noopener noreferrer">{}</MenuLink>}
            {(footer.twitter_url && footer.twitter_url.url.length > 0) &&
            <MenuLink href={footer.twitter_url.url} target="_blank" rel="noopener noreferrer">Twitter</MenuLink>}
            {(footer.instagram_url && footer.instagram_url.url.length > 0) &&
            <MenuLink href={footer.instagram_url.url} target="_blank" rel="noopener noreferrer">Instagram</MenuLink>}
            {(footer.vimeo_url && footer.vimeo_url.url.length > 0) &&
            <MenuLink href={footer.vimeo_url.url} target="_blank" rel="noopener noreferrer">Vimeo</MenuLink>}
            {(footer.linkedin_url && footer.linkedin_url.url.length > 0) &&
            <MenuLink href={footer.linkedin_url.url} target="_blank" rel="noopener noreferrer">LinkedIn</MenuLink>}
            {(footer.dribbble_url && footer.dribbble_url.url.length > 0) &&
            <MenuLink href={footer.dribbble_url.url} target="_blank" rel="noopener noreferrer">Dribbble</MenuLink>}
          </Box>

          <Box width={[1, 1, 1 / 2]}>
            <MailchimpForm onSubmit={(e) => _handleSubmit(e)}>
              <MailchimpElement>
                <input
                  type="text"
                  name="fieldEmail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required='required'
                />

                <label htmlFor="fieldEmail">Subscribe for sporadic updates</label>
              </MailchimpElement>

              <button type="submit"><img src={Arrow} alt=''/></button>

              {statusMessage && (
                <p dangerouslySetInnerHTML={{ __html: statusMessage.msg }}/>
              )}
            </MailchimpForm>
          </Box>
        </FooterContent>

        <Copyright>©{currentYear} Ronik Design LLC. all rights reserved</Copyright>
      </FooterWrap>
    </Footer>
  )
}

export default PageFooter
